<template>
  <div class="privacy">
    <div class="container py-5">
      <div class="row justify-content-center">
        <div class="col-md-10">
          <h3 class="text-center pt-lg-3 pb-2">聯邦網通科技股份有限公司</h3>
          <h3 class="text-center pt-lg-3 pb-2">Union Information Technology Corp.</h3>
          <h3 class="text-center pt-lg-3 pb-5">電子商務網站隱私權聲明</h3>
          <p class="mb-3">「聯邦網通科技股份有限公司」（統一編號:16614905，以下簡稱本公司）非常尊重客戶的隱私權，秉持落實個人資料保護及管理措施制定了「電子商務網站隱私權聲明」，對於個人資料的蒐集、處理及利用，均遵守中華民國「個人資料保護法」及相關法令規範。</p>
          <ul>
            <li>www.papawash.com.tw：papawash，提供美妝商品網路購物電子商務服務</li>
          </ul>
          <p class="mb-5">以上網站及服務名稱，我們均保留新增或更改的權利，更改時不再另行公告。</p>
          <h4 class="mb-3">個人資料蒐集方式</h4>
          <p class="mb-3">
            我們擁有客戶的個人資料，是由在本公司所經營的電子商務網站註冊會員、消費之客戶、使用 Cookie 的技術，由瀏覽器上之數值(包括IP Address、Cookies) 所取得；或是由我們在其他行銷或異業結盟活動中另行合法取得(包括但不限於自業務合作廠商處取得)。
          </p>
          <p class="mb-5">在此提醒您，若您未提供您的個人資料，您可能無法成為我們的會員，享受本公司所提供之各項會員服務；若您未提供完整之正確資訊，您將可能無法享受我們提供之服務或獲得最新資訊。</p>
          <h4 class="mb-3">個人資料蒐集目的</h4>
          <p class="mb-5">
            本公司蒐集、處理、利用之個人資料目的，列示如下：
          </p>
          <ul>
            <li>001人身保險</li>
            <li>040行銷</li>
            <li>063非公務機關依法定義務所進行個人資料之蒐集處理及利用</li>
            <li>067信用卡、現金卡、轉帳卡或電子票證業務</li>
            <li>069契約、類似契約或其他法律關係事務</li>
            <li>090消費者、客戶管理與服務</li>
            <li>091消費者保護</li>
            <li>093財產保險</li>
            <li>098商業與技術資訊</li>
            <li>107採購與供應管理</li>
            <li>111票券業務</li>
            <li>118智慧財產權、光碟管理及其他相關行政</li>
            <li>125傳播行政與管理</li>
            <li>127募款 (包含公益勸募)</li>
            <li>129會計與相關服務</li>
            <li>132經營傳播業務</li>
            <li>136資(通)訊與資料庫管理</li>
            <li>148網路購物及其他電子商務服務</li>
            <li>152廣告或商業行為管理</li>
            <li>157調查、統計與研究分析</li>
            <li>181其他經營合於營業登記項目或組織章程所定之業務</li>
          </ul>
          <h4 class="mb-3">個人資料蒐集種類</h4>
          <p class="mb-3">
            本公司所屬電子商務網站依所提供之服務需要，可能需請您提供的個人資料種類，包括基本資料、帳務資料，分類標準如下：
          </p>
          <ul class="mb-5">
            <li>Ｃ○○一 辨識個人者。<br>(例如：姓名、職稱、住址、工作地址、以前地址、住家電話號碼、行動電話、網路平臺申請之帳號、會員編號、通訊及戶籍地址、電子郵遞地址及其他任何可辨識資料本人者等。)</li>
            <li>Ｃ○○二 辨識財務者。<br>例如：金融機構帳戶之號碼與姓名、信用卡或簽帳卡之號碼、保險單號碼、個人之其他號碼或帳戶等。</li>
            <li>Ｃ○○三 政府資料中之辨識者。<br>例如：身分證統一編號、統一證號、稅籍編號、保險憑證號碼、殘障手冊號碼、退休證之號碼、證照號碼、護照號碼等。</li>
            <li>Ｃ○一一 個人描述。<br>例如：年齡、性別、出生年月日、出生地、國籍、聲音等。</li>
            <li>Ｃ○一二 身體描述。<br>例如：身高、體重、血型等。</li>
            <li>Ｃ○二一 家庭情形。<br>例如：結婚有無、配偶或同居人之姓名、前配偶或同居人之姓名、結婚之日期、子女之人數等。</li>
            <li>Ｃ○二三 家庭其他成員之細節。<br>例如：子女、受扶養人、家庭其他成員或親屬、父母、同居人及旅居國外及大陸人民親屬等。</li>
            <li>Ｃ○三一 住家及設施。<br>例如：住所地址、設備之種類、所有或承租、住用之期間、租金或稅率及其他花費在房屋上之支出、房屋之種類、價值及所有人之姓名等。</li>
            <li>Ｃ○三三 移民情形。<br>例如：護照、工作許可文件、居留證明文件、住居或旅行限制、入境之條件及其他相關細節等。</li>
            <li>Ｃ○三四 旅行及其他遷徙細節。<br>例如：過去之遷徙、旅行細節、外國護照、居留證明文件及工作證照及工作證等相關細節等。</li>
            <li>Ｃ○三六 生活格調。<br>例如：使用消費品之種類及服務之細節、個人或家庭之消費模式等。</li>
            <li>Ｃ○三八 職業。<br>例如：學校校長、民意代表或其他各種職業等。</li>
            <li>Ｃ○八一 收入、所得、資產與投資。<br>例如：總收入、總所得、賺得之收入、賺得之所得、資產、儲蓄、開始日期與到期日、投資收入、投資所得、資產費用等。</li>
            <li>Ｃ○九三 財務交易。<br>例如：收付金額、信用額度、保證人、支付方式、往來紀錄、保證金或其他擔保等。</li>
            <li>Ｃ一○二 約定或契約。<br>例如：關於交易、商業、法律或其他契約、代理等。</li>
            <li>Ｃ一三二 未分類之資料。<br>例如：無法歸類之信件、檔案、報告或電子郵件等。</li>
          </ul>
          <h4 class="mb-3">個人資料利用期間、地區及對象：</h4>
          <p class="mb-3">
            本公司將持續使用您提供的個人資料直至您提出停止使用或本網站停止提供服務之日為止。您的個人資料使用區域僅限於台灣地區內使用。
          </p>
          <p class="mb-3">
            本公司電子商務網站伺服器自動記錄顧客所閱讀的網頁、顧客所來自的網域名稱，作為內容與系統改進、廣告效果評估之依據。我們可能為進行商業及市場分析，並提供更適合客戶商品之故，在本公司、或合作之其他機構間運用您同意提供的基本資料、其他資料。
          </p>
          <p class="mb-3">
            為提供您更完善、更多元的服務以及更好之服務品質，我們將會在法令許可或您同意下，將您的資料提供給合作之商品配送業者、商品供應商或關係企業，以提供您所需的個別服務、行銷活動及相關優惠訊息。
          </p>
          <p class="mb-5">
            我們僅會在本公司及我們所委任處理營業相關事務之第三人(例如：金融機構、合作廠商、物流商等服務提供者)，依照前述服務目的範圍為作業之必要利用個人資料。除非另有法令規範，或另行取得您的同意，否則我們不會向前述以外之第三人揭露您的個人資料。
          </p>
          <h4 class="mb-3">個人資料儲存及保管方式</h4>
          <p class="mb-3">
            關於您提供的個人資料，我們將就資訊系統及公司作業規則制定嚴格規範，任何人均須在我們明定的授權規範下才能處理及利用必要之資料。我們針對資料安全設備投注之保護措施如下：
          </p>
          <ul class="mb-5">
            <li>我們採用最佳的科技來保障您的個人資料安全。目前以 Secure Sockets Layer(SSL) 機制(128bit)進行資料傳輸加密，並以加裝防火牆防止不法入侵 ，避免您的個人資料遭到非法存取。</li>
            <li>我們於2008年01月通過英國標準協會 BSI 國際認證公司驗證取得 ISO/IEC 27001 資訊安全驗證，以確保您的資料在多層的資訊安全控管下，做到最高規格的防護。</li>
          </ul>
          <h4 class="mb-3">個人資料權利行使方式</h4>
          <p class="mb-3">
            本公司所蒐集個人資料之當事人得依法向本公司行使以下權利：
          </p>
          <ul class="list-unstyled mb-3">
            <li>
              <div class="d-flex mb-2 ms-1">(1)<div class="ms-2">查詢或請求閱覽。</div></div>
            </li>
            <li>
              <div class="d-flex mb-2 ms-1">(2)<div class="ms-2">請求製給複製本。</div></div>
            </li>
            <li>
              <div class="d-flex mb-2 ms-1">(3)<div class="ms-2">請求補充或更正。</div></div>
            </li>
            <li>
              <div class="d-flex mb-2 ms-1">(4)<div class="ms-2">請求停止蒐集、處理或利用。</div></div>
            </li>
            <li>
              <div class="d-flex mb-2 ms-1">(5)<div class="ms-2">請求刪除。</div></div>
            </li>
          </ul>
          <p class="mb-3">
            如果您就您的個人資料向本公司請求答覆查詢、提供閱覽或製給複製本時，本公司將酌收必要成本費用。而前述之申請，應填具申請文件，本公司電子商務網站得向您請求提出可資確認之身分證明文件；若委託他人代為申請者，並應出具委任書，且提供本人及代理人之身分證明文件。
          </p>
          <p class="mb-5">
            如果您的個人資料有變更，您可自行於本公司電子商務網站進行更正或致電客服中心修正您的個人資料
          </p>
          <p class="mb-3">
            我們將因應科技發展趨勢、相關法規之修訂或其他環境變遷等因素而對本網站隱私權保護作適當之修改，以落實保障使用者隱私權之立意。
          </p>
          <p class="mb-5">
            關於此份隱私權聲明，我們得隨時因應社會環境及法令規定的變遷與科技的進步進行修改，並將修改內容以電子郵件、電話、通信網路、網路公告或其他適當方式通知客戶；當您使用 Papawash 購物網所提供之任一服務時，即表示您已閱讀、瞭解並同意接受本服務條款之所有內容及其後修改變更。
          </p>
          <h4 class="mb-3">電子發票作業個資保護告知條款</h4>
          <p class="mb-3">
            客戶於 Papawash 購物網進行消費時，開立電子發票請知悉下述聲明：
          </p>
          <ul class="list-unstyled mb-3">
            <li>
              <div class="d-flex mb-2 ms-1">一.<div class="ms-2">個資蒐集主體：聯邦網通科技股份有限公司</div></div>
            </li>
            <li>
              <div class="d-flex mb-2 ms-1">二.
                <div class="ms-2">核准使用電子發票之字號及日期：
                  <ul class="list-unstyled">
                    <li>本公司奉核自民國 109 年 12 月 9 日起開始適用電子發票</li>
                    <li>核准文號：財北國稅中正營業字第 1091704402 號函</li>
                  </ul>
                </div>
              </div>
            </li>
            <li>
              <div class="d-flex mb-2 ms-1">三.
                <div class="ms-2">本公司個資蒐集目的：
                  <ul class="list-unstyled">
                    <li>Ｏ六三 非公務機關依法定義務所進行個人資料之蒐集處理及利用</li>
                    <li>Ｏ六九 契約、類似契約或其他法律關係事務</li>
                    <li>Ｏ九Ｏ 消費者、客戶管理與服務</li>
                    <li>Ｏ九五 財稅行政、調查、統計與研究分析</li>
                    <li>一三六 資（通）訊與資料庫管理</li>
                    <li>一四八 網路及其他電子商務服務</li>
                    <li>一七九 其他財政服務及配合財政部電子發票整合服務</li>
                  </ul>
                </div>
              </div>
            </li>
            <li>
              <div class="d-flex mb-2 ms-1">四.<div class="ms-2">個資之類別：姓名、電子郵件信箱、聯絡地址、電子發票號碼、消費時間、消費明細、店點等發票資料。</div></div>
            </li>
            <li>
              <div class="d-flex mb-2 ms-1">五.<div class="ms-2">個人資料利用之期間、地區：本公司營運地區及依法保存會計憑證期限內。</div></div>
            </li>
            <li>
              <div class="d-flex mb-2 ms-1">六.
                <div class="ms-2">個人資料利用之對象、方式：
                  <ul class="list-unstyled">
                    <li>本公司基於配合財政部電子發票整合服務之特定目的內，將電子發票相關資料上傳至財政部電子發票整合服務平台（網址<a href="http://www.einvoice.nat.gov.tw/" target="_blank">http://www.einvoice.nat.gov.tw/</a>），供其於「Ｏ九五財稅行政」、「一五七調查、統計與研究分析」、「一三六資（通）訊與資料庫管理」、「一四八網路購物及其他電子商務服務」、「一七九其他財政服務」及提供電子發票加值服務目的內處理利用。</li>
                  </ul>
                </div>
              </div>
            </li>
            <li>
              <div class="d-flex mb-2 ms-1">七.
                <div class="ms-2">電子發票相關資訊：
                  <ul class="list-unstyled">
                    <li>會員就查詢、捐贈、兌獎及電子發票有關之資訊，可至本公司官網：https://www.papawash.com.tw「會員中心」。</li>
                  </ul>
                </div>
              </div>
            </li>
            <li>
              <div class="d-flex mb-2 ms-1">八.<div class="ms-2">當事人得自由提供資料時，不提供對其權益之影響：若會員資料有填寫未盡正確缺漏之處，可能影響服務提供或商品寄送，會員得隨時停止以載具索取電子發票而改索取紙本電子發票，停用不影響用戶既有權益，若有遺失或毀損情事時，請洽本公司服務窗口辦理。</div></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
